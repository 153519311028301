import { useState, useEffect } from 'react';

function CryptoPayment({ amount, onClose }) {
  const [selectedCrypto, setSelectedCrypto] = useState('btc');
  const [cryptoPrices, setCryptoPrices] = useState({});
  const [loading, setLoading] = useState(true);

  const wallets = { "btc":"bc1qvpp64apaj2l00n6md4um2x9k44u8uhkpslk05v",
    "ltc":"Li2fEVD1XNWQwgjGS6gCvDdwDiPwWcVuza",
    "doge":"DQ8rfRKqvsw2K23MyF427A3TC8b5sWtk7D",
    "xmr":"43sRpX6pjsf6o7gVh4RfnH9hvPv7zk96cB411fhgAoQFH7E6RWnGAjhQDR3AddSkse6gRb1Le82F1aMQeALrnTCpQ7tSXN5",
    "trx":"TYvd3aiF622PDBRseKgfBkzc15J9vymbMK",
    "sol":"8u5xBKe7Mini5tmtX5uZz6WJLJpqTmQtH4gz3JwERqsV",
    "erc20":"0x194e124bDB282b1C2d0943e758d4744C236C7513",
    "trc20":"TYvd3aiF622PDBRseKgfBkzc15J9vymbMK"}

  const [showQR, setShowQR] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    fetch("https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,litecoin,dogecoin,monero,tron,solana,tether&vs_currencies=usd")
      .then(res => res.json())
      .then(data => {
        setCryptoPrices({
          btc: data.bitcoin.usd,
          ltc: data.litecoin.usd,
          doge: data.dogecoin.usd,
          xmr: data.monero.usd,
          sol: data.solana.usd,
          trx: data.tron.usd,
          erc20: data.tether.usd,
          trc20: data.tether.usd
        });
        setLoading(false);
      });
  }, []);

  const getCryptoAmount = () => {
    if (!cryptoPrices[selectedCrypto]) return '0.00000000';
    return (amount / cryptoPrices[selectedCrypto]).toFixed(8);
  };

  const copyAddress = () => {
    navigator.clipboard.writeText(wallets[selectedCrypto]);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const getQRCode = () => {
    const uri = `${selectedCrypto}:${wallets[selectedCrypto]}?amount=${getCryptoAmount()}`;
    return `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(uri)}`;
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content bg-dark text-light p-4">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2>Pay with Crypto</h2>
          <button 
            className="btn-close btn-close-white" 
            onClick={onClose}
          ></button>
        </div>

        <div className="mb-4">
          <label className="form-label">Choose payment method:</label>
          <select 
            className="form-select bg-dark text-light"
            value={selectedCrypto}
            onChange={(e) => setSelectedCrypto(e.target.value)}
          >
            <option value="btc">Bitcoin (BTC)</option>
            <option value="ltc">Litecoin (LTC)</option>
            <option value="doge">Dogecoin (DOGE)</option>
            <option value="xmr">Monero (XMR)</option>
            <option value="sol">Solana (SOL)</option>
            <option value="trx">Tron (TRX)</option>
            <option value="erc20">Tether (USDT)(ETH ERC-20)</option>
            <option value="trc20">Tether (USDT)(TRX TRC-20)</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="form-label">
            {selectedCrypto.toUpperCase()} Address:
          </label>
          <div className="input-group">
            <input 
              type="text" 
              className="form-control bg-dark text-light"
              value={wallets[selectedCrypto]}
              readOnly
            />
            <button 
              className="btn btn-outline-light"
              onClick={copyAddress}
            >
              {copied ? 'Copied!' : 'Copy'}
            </button>
          </div>
        </div>

        <div className="mb-4">
          <p>Amount to pay: <strong>{getCryptoAmount()} {selectedCrypto.toUpperCase()}</strong></p>
          <p>(${amount.toFixed(2)} USD)</p>
        </div>

        {!['erc20', 'trc20'].includes(selectedCrypto) && (
          <div className="text-center mb-4">
            <button 
              className="btn btn-outline-light mb-3"
              onClick={() => setShowQR(!showQR)}
            >
              {showQR ? 'Hide QR Code' : 'Show QR Code'}
            </button>
            {showQR && (
              <div className="mt-2">
                <img 
                  src={getQRCode()} 
                  alt="Payment QR Code" 
                  className="img-fluid"
                  style={{ maxWidth: '200px' }}
                />
              </div>
            )}
          </div>
        )}

        <div className="alert alert-info">
          <h5>Instructions:</h5>
          <ol>
            <li>Copy the {selectedCrypto.toUpperCase()} address above</li>
            <li>Open your crypto wallet and paste the address</li>
            <li>Send exactly {getCryptoAmount()} {selectedCrypto.toUpperCase()}</li>
            <li>Wait for payment confirmation</li>
          </ol>
        </div>

        <div className="text-center">
          <p className="mb-3">
            Status: Waiting for payment
            <span className="dots"></span>
          </p>
          <button 
            className="btn btn-primary w-100" 
            disabled
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default CryptoPayment; 