import './App.css';
import { useState } from 'react';
import CryptoPayment from './components/CryptoPayment';
import { sendDiscordWebhook } from './utils/webhook';
import VerificationGallery from './components/VerificationGallery';

function App() {
  const [inputKink, setInputKink] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  const [showKinkList, setShowKinkList] = useState(false);
  const [showOrderForm, setShowOrderForm] = useState(false);
  const [orderType, setOrderType] = useState('');
  const [deliveryMethod, setDeliveryMethod] = useState('email');
  const [contactInfo, setContactInfo] = useState('');
  const [orderDetails, setOrderDetails] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [showCryptoPayment, setShowCryptoPayment] = useState(false);
  const [showVerification, setShowVerification] = useState(false);

  const allowedKinks = [
    'feet', 'roleplay', 'cosplay', 'lingerie', 'stockings',
    'masturbation', 'striptease', 'joi', 'nudity', 'dancing',
    'toys', 'dirty talk', 'fingering', 'anal', "beastiality", "incest",
    'bondage', 'domination', 'submission', 'cutting', 'choking',
    'squirting', 'edging', 'orgasm control', "pissing", "shitting",
    'foot worship', 'humiliation', 'cross dressing', 'basically anything'
  ];

  const deliveryOptions = [
    { value: 'email', label: 'Email' },
    { value: 'telegram', label: 'Telegram' },
    { value: 'discord', label: 'Discord' },
    { value: 'snapchat', label: 'Snapchat' },
    { value: 'kik', label: 'Kik' },
    { value: 'twitter', label: 'Twitter (X)' },
    { value: 'instagram', label: 'Instagram' }
  ];

  const checkKink = () => {
    const found = allowedKinks.some(kink => 
      kink.toLowerCase() === inputKink.toLowerCase().trim()
    );
    setIsAllowed(found);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 5000); // Hide alert after 5 seconds
  };

  const handleOrder = (type) => {
    setOrderType(type);
    setShowOrderForm(true);
  };

  const handleSubmitOrder = async (e) => {
    e.preventDefault();
    
    try {
      await sendDiscordWebhook({
        orderType: orderType,
      quantity: quantity,
      total: calculateTotal(),
      deliveryMethod: deliveryMethod,
      contactInfo: contactInfo,
        details: orderDetails
      });
    } catch (error) {
      console.error('Error sending webhook:', error);
    }

    setShowOrderForm(false);
    setShowCryptoPayment(true);
  };

  const calculateTotal = () => {
    switch(orderType) {
      case 'images':
        return (quantity * 2.50).toFixed(2);
      case 'videos':
        return (quantity * 5).toFixed(2);
      case 'package':
        return '25.00';
      default:
        return '0.00';
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Ava the seller</h1>
        <div className="profile-section">
          <img 
            src="https://small.fileditchstuff.me/s19/uepObdCaydBrPiWaoUX.png" 
            alt="Ava's Profile" 
            className="profile-image" 
            width={150}
            height={150}
          />
          
          <p className="profile-bio">
            Hi! I'm Aly, and i sell adult content. Either custom content or pre-made package. I'm open to almost any kinks.
          </p>
          <p>Check if your kink is in the list before ordering:</p>
          <div className="kink-checker mb-4">
            <input
              type="text"
              className="form-control mb-2"
              placeholder="Enter kink to check"
              value={inputKink}
              onChange={(e) => setInputKink(e.target.value)}
            />
            <div className="d-flex gap-2 mb-2">
              <button 
                className="btn btn-primary"
                onClick={checkKink}
              >
                Check Kink
              </button>
              <button 
                className="btn btn-secondary"
                onClick={() => setShowKinkList(!showKinkList)}
              >
                {showKinkList ? 'Hide List' : 'Show Full List'}
              </button>
            </div>
            
            {showKinkList && (
              <div className="alert alert-info mb-2">
                <h5>Allowed Kinks:</h5>
                <div className="d-flex flex-wrap gap-2">
                  {allowedKinks.map(kink => (
                    <span key={kink} className="badge bg-primary">{kink}</span>
                  ))}
                </div>
              </div>
            )}

            {showAlert && (
              <div className={`alert alert-${isAllowed ? 'success' : 'danger'} alert-dismissible fade show`} role="alert">
                {isAllowed 
                  ? "✅ This kink is allowed! Feel free to request it." 
                  : "❌ Sorry, this kink is not available."}
                <button type="button" className="btn-close" onClick={() => setShowAlert(false)}></button>
              </div>
            )}
          </div>
          <button 
            className="btn btn-outline-light mt-2"
            onClick={() => setShowVerification(true)}
          >
            Show Verification Photos
          </button>
          <p>Webside made by my brother</p>
        </div>
        <div className="content-grid">
          <div className="content-item">
            <h3>Custom Images</h3>
            <p>1 per $2.50</p>
            <p>Includes:</p>
            <ul>
              <li>Fansigns on any part of my body</li>
              <li>boob, ass or pussy pics</li>
              <li>Kinks content based on your request</li>
            </ul>
            <button className="cta-button" onClick={() => handleOrder('images')}>Order Now</button>
          </div>
          <div className="content-item">
            <h3>Custom Videos</h3>
            <p>1 minute per $5</p>
            <p>It can be recorded nudes or custom content based of your request</p>
            <button className="cta-button" onClick={() => handleOrder('videos')}>Order Now</button>
          </div>
          <div className="content-item">
            <h3>Full Premade Package - $25</h3>
            <p>Includes:</p>
            <ul>
              <li>Over 400 images of my nudes</li>
              <li>Over 50 videos of my recorded content</li>
              <li>Some images and videos contain kinks</li>
            </ul>
            <button className="cta-button" onClick={() => handleOrder('package')}>Buy Package</button>
          </div>

          {showOrderForm && (
            <div className="modal-overlay">
              <div className="modal-content bg-dark text-light">
                <form onSubmit={handleSubmitOrder} className="order-form">
                  <h4>{orderType === 'package' ? 'Package Delivery Details' : `Custom ${orderType} Order`}</h4>
                  <button 
                    type="button" 
                    className="btn-close btn-close-white position-absolute top-0 end-0 m-2"
                    onClick={() => setShowOrderForm(false)}
                  ></button>
                  
                  {orderType !== 'package' && (
                    <>
                      <div className="mb-3">
                        <label className="form-label">
                          {orderType === 'images' ? 'Number of Images' : 'Video Length (minutes)'}
                        </label>
                        <div className="input-group">
                          <button 
                            type="button" 
                            className="btn btn-outline-light" 
                            onClick={() => setQuantity(Math.max(1, quantity - 1))}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            className="form-control text-center bg-dark text-light"
                            value={quantity}
                            onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                            min="1"
                            required
                          />
                          <button 
                            type="button" 
                            className="btn btn-outline-light"
                            onClick={() => setQuantity(quantity + 1)}
                          >
                            +
                          </button>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="form-label">What would you like to see?</label>
                        <textarea
                          className="form-control bg-dark text-light"
                          rows="4"
                          value={orderDetails}
                          onChange={(e) => setOrderDetails(e.target.value)}
                          placeholder={orderType === 'images' 
                            ? "Describe request for your custom images..."
                            : "Describe your custom video request, including any specific actions, kinks or what i should do..."}
                          required
                        ></textarea>
                      </div>
                    </>
                  )}

                  <div className="mb-3">
                    <label className="form-label">Delivery Method</label>
                    <select 
                      className="form-select bg-dark text-light"
                      value={deliveryMethod}
                      onChange={(e) => setDeliveryMethod(e.target.value)}
                      required
                    >
                      {deliveryOptions.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      {deliveryMethod === 'email' ? 'Email Address' : `${deliveryMethod} Username/Link`}
                    </label>
                    <input
                      type={deliveryMethod === 'email' ? 'email' : 'text'}
                      className="form-control bg-dark text-light"
                      value={contactInfo}
                      onChange={(e) => setContactInfo(e.target.value)}
                      placeholder={deliveryMethod === 'email' 
                        ? "Enter your email address"
                        : `Enter your ${deliveryMethod} username or link`}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <h5 className="text-end">
                      Total: ${calculateTotal()}
                    </h5>
                  </div>

                  <button type="submit" className="btn btn-primary w-100">
                    Proceed to Payment
                  </button>
                </form>
              </div>
            </div>
          )}

          {showCryptoPayment && (
            <CryptoPayment 
              amount={parseFloat(calculateTotal())}
              onClose={() => setShowCryptoPayment(false)}
            />
          )}

          {showVerification && (
            <VerificationGallery 
              onClose={() => setShowVerification(false)}
            />
          )}
        </div>
      </header>
    </div>
  );
}

export default App;