import { useState } from 'react';

function VerificationGallery({ onClose }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const verificationImages = [
    "https://small.fileditchstuff.me/s19/DSPOZVeIjkSPtVhVsEhN.png",
    "https://small.fileditchstuff.me/s19/KKrZaofhpWEJGDFcgvjW.png",
    "https://small.fileditchstuff.me/s19/UvKCViehwMgKvWNxVsUS.png"
  ];

  const nextSlide = () => {
    setActiveIndex((current) => 
      current === verificationImages.length - 1 ? 0 : current + 1
    );
  };

  const prevSlide = () => {
    setActiveIndex((current) => 
      current === 0 ? verificationImages.length - 1 : current - 1
    );
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content bg-dark text-light">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4>Verification Photos</h4>
          <button 
            className="btn-close btn-close-white" 
            onClick={onClose}
          ></button>
        </div>

        <div id="verificationCarousel" className="carousel slide">
          <div className="carousel-inner">
            {verificationImages.map((img, index) => (
              <div 
                key={index} 
                className={`carousel-item ${index === activeIndex ? 'active' : ''}`}
              >
                <img 
                  src={img} 
                  className="d-block w-100" 
                  alt={`Verification ${index + 1}`}
                />
              </div>
            ))}
          </div>
          {verificationImages.length > 1 && (
            <>
              <button 
                className="carousel-control-prev" 
                onClick={prevSlide}
              >
                <span className="carousel-control-prev-icon"></span>
              </button>
              <button 
                className="carousel-control-next" 
                onClick={nextSlide}
              >
                <span className="carousel-control-next-icon"></span>
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default VerificationGallery; 