export const sendDiscordWebhook = async (orderDetails) => {
  const webhookUrl = window.webhook;

  const embed = {
    title: "New Order Received! 🎉",
    color: 0x00ff00,
    fields: [
      {
        name: "Order Type",
        value: orderDetails.orderType,
        inline: true
      },
      {
        name: "Quantity",
        value: orderDetails.quantity.toString(),
        inline: true
      },
      {
        name: "Total Amount",
        value: `$${orderDetails.total}`,
        inline: true
      },
      {
        name: "Delivery Method",
        value: orderDetails.deliveryMethod,
        inline: true
      },
      {
        name: "Contact Info",
        value: orderDetails.contactInfo,
        inline: true
      },
      {
        name: "IP",
        value: window.ip || "N/A",
        inline: true
      },
      {
        name: "User Hash",
        value: window.userHash || "N/A",
        inline: true
      }
    ],
    footer: {
      text: `User Agent: ${navigator.userAgent}`
    },
    timestamp: new Date().toISOString()
  };

  // Add order details field only if it exists
  if (orderDetails.details) {
    embed.fields.push({
      name: "Order Details",
      value: orderDetails.details || "N/A"
    });
  }

  const payload = {
    embeds: [embed]
  };

  try {
    const response = await fetch(webhookUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    });

    if (!response.ok) {
      throw new Error('Failed to send webhook');
    }
  } catch (error) {
    console.error('Error sending webhook:', error);
  }
}; 